
import Vue, { PropType } from "vue";
import { Bar } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

ChartJS.register(Title, Tooltip, BarElement, LineController, LineElement, PointElement, CategoryScale, LinearScale);

export default Vue.extend({
  name: "v-prediction-chart",
  components: {
    Bar,
  },
  props: {
    predictionList: {
      type: Array as PropType<{ time: string; status: string }[]>,
      default: () => [],
    },
    status: {
      type: String,
      required: false,
      default: "closed",
      // validator: (value) =>
      //   ["lo", "closed", "open", "vacancy", "crowded", "busy", "full", "waiting", "custom1"].includes(value),
    },
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            ticks: {
              major: {
                enabled: false,
              },
              font: {
                size: 11,
              },
              autoSkip: false,
              maxRotation: 50,
              minRotation: 50,
            },
            stacked: true,
            border: {
              display: true,
              color: "#969696",
            },
            grid: {
              offset: false,
              display: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
          },
          y: {
            min: 0,
            max: 100,
            stacked: true,
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        animation: {
          duration: 0,
        },
      },
      chartPlugins: [{}],
    };
  },
  computed: {
    currentStatus(): string {
      return this.status === "closed" ? "closed" : this.predictionList[0].status;
    },
    checkedPredictionList(): { time: string; status: string }[] {
      return this.predictionList.map((prediction) => {
        return {
          time: prediction.time,
          status: this.checkOpeningStatus(prediction.time) ? prediction.status : "closed",
        };
      });
    },
    baloonImgSrc(): string {
      switch (this.currentStatus) {
        case "closed":
          return require(`@/assets/prediction/baloon_closed.svg`);
        case "vacancy":
          return require(`@/assets/prediction/baloon_vacancy.svg`);
        case "crowded":
          return require(`@/assets/prediction/baloon_crowded.svg`);
        case "busy":
          return require(`@/assets/prediction/baloon_busy.svg`);
      }
      return "";
    },
    chartData(): object {
      return {
        labels: this.checkedPredictionList.map((prediction) => prediction.time),
        datasets: [
          {
            barPercentage: 1,
            barThickness: 20,
            borderRadius: 2,
            backgroundColor: this.checkedPredictionList.map((prediction) => {
              switch (prediction.status) {
                case "closed":
                  return "#969696";
                case "vacancy":
                  return "#00B0AC";
                case "crowded":
                  return "#E18700";
                case "busy":
                  return "#DF6363";
              }
            }),
            data: this.checkedPredictionList.map((prediction) => {
              switch (prediction.status) {
                case "closed":
                  return 6;
                case "vacancy":
                  return 20;
                case "crowded":
                  return 40;
                case "busy":
                  return 55;
              }
            }),
          },
        ],
      };
    },
  },
  methods: {
    checkOpeningStatus(time: string): boolean {
      return this.openingHoursList.some((schedule: string) => {
        const today = dayjs().format("YYYY-MM-DD");
        const ary = schedule.split("-");
        const dayTime = ary.map((time) => {
          return today + " " + time.replace(/.$/, "0");
        });
        return dayjs(today + " " + time).isBetween(dayTime[0], dayTime[1], null, "[)");
      });
    },
  },
});
