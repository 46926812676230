
import Vue from "vue";
import VIcon from "@/components/v/icon.vue";
import * as storage from "@/api/common/local-storage";
import { postQuickSurveyAnswer } from "@/api/enterprise-vacan.adapter.api";

interface DataType {
  showQuickSurvey: boolean;
  showThankYou: boolean;
  quickSurveyResponse: number | undefined;
}

type L10nString = {
  en: string;
  ja: string;
  "ko-KR": string;
  "zh-CN": string;
  "zh-TW": string;
};

interface Choice {
  label: string;
  image: string;
  classes: string[];
  textClasses: string[];
}

export default Vue.extend({
  name: "components-quick-survey",
  components: {
    VIcon,
  },
  props: {
    websiteId: { type: String, required: true },
    theme: { type: String, required: true },
  },
  data(): DataType {
    return {
      showQuickSurvey: false,
      showThankYou: false,
      quickSurveyResponse: undefined,
    };
  },
  computed: {
    locale(): keyof L10nString {
      const locale = this.$i18n.locale;
      const list = ["en", "ja", "ko-KR", "zh-CN", "zh-TW"];
      const defaultLocale: keyof L10nString = "en";
      return list.includes(locale) ? (locale as keyof L10nString) : defaultLocale;
    },
    localStorageKey(): string {
      return `quick-survey-time-${this.websiteId}`;
    },
    quickSurveyChoices(): Choice[] {
      const isDarkMode = this.theme === "black";
      return [
        {
          label: {
            en: "Inconvenient",
            ja: "不便",
            "ko-KR": "불편",
            "zh-CN": "不方便",
            "zh-TW": "不方便",
          },
          image: isDarkMode
            ? require("@/assets/quick-survey/dark-face0.png")
            : require("@/assets/quick-survey/face0.png"),
          imageSelected: isDarkMode
            ? require("@/assets/quick-survey/dark-face0-selected.png")
            : require("@/assets/quick-survey/face0-selected.png"),
          selectedColorClass: "selected-color2",
        },
        {
          label: {
            en: "Convenient",
            ja: "便利",
            "ko-KR": "편리",
            "zh-CN": "方便",
            "zh-TW": "方便",
          },
          image: isDarkMode
            ? require("@/assets/quick-survey/dark-face1.png")
            : require("@/assets/quick-survey/face1.png"),
          imageSelected: isDarkMode
            ? require("@/assets/quick-survey/dark-face1-selected.png")
            : require("@/assets/quick-survey/face1-selected.png"),
          selectedColorClass: "selected-color",
        },
        {
          label: {
            en: "Very convenient",
            ja: "とても便利",
            "ko-KR": "매우 편리",
            "zh-CN": "很方便",
            "zh-TW": "很方便",
          },
          image: isDarkMode
            ? require("@/assets/quick-survey/dark-face2.png")
            : require("@/assets/quick-survey/face2.png"),
          imageSelected: isDarkMode
            ? require("@/assets/quick-survey/dark-face2-selected.png")
            : require("@/assets/quick-survey/face2-selected.png"),
          selectedColorClass: "selected-color",
        },
      ].map((i, index) => {
        const remove = this.quickSurveyResponse !== undefined && index !== this.quickSurveyResponse;
        return {
          label: i.label[this.locale],
          image: index === this.quickSurveyResponse ? i.imageSelected : i.image,
          classes: [remove ? "opacity-zero" : "opacity-one"],
          textClasses: [index === this.quickSurveyResponse ? i.selectedColorClass : "regular-color"],
        };
      });
    },
  },
  created() {
    if (this.websiteId === undefined) {
      throw Error("no website id");
    }
    const lastResponseTime = storage.get(this.localStorageKey);
    if (lastResponseTime) {
      const parsed = parseInt(lastResponseTime);
      if (!isNaN(parsed)) {
        const expireDays = 180;
        const expire = parsed + expireDays * 24 * 60 * 60 * 1000;
        if (new Date().getTime() < expire) {
          this.showQuickSurvey = false;
          return;
        }
      }
    }
    const delayMsec = 5000; //https://app.asana.com/0/1202289590921351/1202582265011470/f
    setTimeout(() => {
      this.showQuickSurvey = true;
    }, delayMsec);
  },
  methods: {
    onCloseQuckSurvey() {
      this.showQuickSurvey = false;
    },
    onRespondQuickSurvey(index: number) {
      if (this.quickSurveyResponse !== undefined) {
        return;
      }
      this.quickSurveyResponse = index;
      postQuickSurveyAnswer({
        websiteId: this.websiteId,
        responseIndex: index,
        language: this.$i18n.locale,
      })
        .then(() => {
          storage.set(this.localStorageKey, new Date().getTime());
          setTimeout(() => {
            this.showThankYou = true;
          }, 1000);
          setTimeout(() => {
            this.onCloseQuckSurvey();
          }, 5000);
        })
        .catch(() => {
          this.onCloseQuckSurvey();
          this.$store.commit("setAlertView", {
            status: true,
          });
        });
    },
  },
});
