
import Vue, { PropType } from "vue";

import VDateSelector from "@/components/place/forecast/date-selector.vue";
import { Weekday } from "@/store";
import { isOmachishi } from "@/api/omachishi-customization";

export default Vue.extend({
  name: "the-header",
  components: {
    VDateSelector,
  },
  props: {
    label: { type: String, default: "" },
    mode: { type: String, default: "vacancy", validator: (mode: string) => ["vacancy", "forecast"].includes(mode) },
    viewType: {
      type: String as PropType<"vacancy" | "forecast">,
      default: "vacancy",
      validator: (viewType: string) => ["vacancy", "forecast"].includes(viewType),
    },
    weekdayList: { type: Array as PropType<Weekday[]>, default: () => [] },
    selectedDate: { type: String, default: "" },
  },
  data() {
    return {
      fixViewType: false,
      scrollPosition: 0,
      scrollUp: true,
    };
  },
  computed: {
    innerViewType: {
      get(): string {
        return this.viewType;
      },
      set(value: string) {
        this.$gtag.event("Change Forecast View", { event_label: value });
        this.$emit("change-view", value);
      },
    },
    showCongestionInfo(): boolean {
      return isOmachishi(this.$route.params.websiteId);
    },
  },
  watch: {
    viewType: {
      handler() {
        this.$nextTick().then(() => {
          this.scrollUp = true;
          window.scrollTo({ top: this.scrollPosition });
        });
      },
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const ref = this.$refs.header as Element | undefined;

      if (!ref || !ref.getBoundingClientRect) {
        return;
      }

      const bound = ref.getBoundingClientRect();

      this.fixViewType = bound.top < -82;

      const currentPosition = window.scrollY;
      this.scrollUp = this.scrollPosition > currentPosition;
      this.scrollPosition = currentPosition;
    },
    onDateSelected(date: string) {
      this.$emit("change-date", date);
    },
    openOmachishiModal() {
      this.$emit("open-omachishi-modal");
    },
  },
});
