/* eslint-disable @typescript-eslint/no-explicit-any */
export function set(key: string, value: any): void {
    localStorage.setItem(`common/${key}`, JSON.stringify(value));
}

export function remove(key: string): void {
    localStorage.removeItem(`common/${key}`);
}

export function get(key: string): any | undefined {
    const item = localStorage.getItem(`common/${key}`);
    return item !== null ? JSON.parse(item) : undefined;
}
