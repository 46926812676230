
import Vue from "vue";

import { Ticket } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import VButton from "@/components/v/button.vue";
import * as storage from "@/api/local-storage";
import { isPC } from "@/utils/mobile";

interface DataNotificationModal {
  isShowNotificationModal: boolean;
}

export default Vue.extend({
  name: "notification-modal",
  components: {
    VButton,
  },
  props: {
    placeName: { type: String, default: undefined },
    params: { type: Object, default: undefined },
    qticketConfig: {
      type: Object as () => (Ticket.TicketConfig & { placeIdHash: string }) | undefined,
      default: undefined,
    },
    modalCondition: { type: Boolean, default: false },
  },
  data(): DataNotificationModal {
    return {
      isShowNotificationModal: false,
    };
  },
  computed: {
    isDesktop(): boolean {
      return isPC();
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.body.style.overflow = "";
  },
  methods: {
    closeNotificationModal(): void {
      this.$emit("closeModal", this.isShowNotificationModal);
    },
    useLine(): void {
      storage.set("USE_LINE_NOTIFICATION", true);
      if (this.isDesktop) {
        storage.remove("SKIP_TUTORIAL");
        return;
      }
    },
  },
});
