
import Vue from "vue";

export default Vue.extend({
  name: "congestion-rate-text",
  props: {
    status: {
      type: String,
      required: false,
      default: "closed",
      validator: (value: string) =>
        ["lo", "closed", "open", "vacancy", "crowded", "busy", "full", "waiting", "custom1"].includes(value),
    },
  },
  computed: {
    text(): string {
      switch (this.status) {
        case "vacancy":
          return "（利用率 50％未満）";
        case "crowded":
          return "（利用率 50％～74％）";
        case "busy":
        case "waiting":
        case "full":
          return "（利用率 75％以上）";
        default:
          return "";
      }
    },
    isLongText(): boolean {
      return this.text.length > 20;
    },
  },
});
