import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { Locales } from "locale";

import "dayjs/locale/ja";
import "dayjs/locale/zh";
import "dayjs/locale/ko";
import "dayjs/locale/zh-tw";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages: LocaleMessages = {};
    locales.keys().forEach((key: string) => {
        const matched = key.match(/([A-Za-z0-9-_,\s]+)\./i);
        if (matched && matched.length > 1) {
            // match file names
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const browserLocale: string =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || "en";

const supportLanguage = ["en", "ja", "ko-KR", "zh-CN", "zh-TW"] as const; // correspond to file name of json files in locales directory
export type SupportLanguage = (typeof supportLanguage)[number];

const supportLanguageForAPI = ["en", "ja", "ko_KR", "zh_CN", "zh_TW"] as const;
type SupportLanguageForAPI = (typeof supportLanguageForAPI)[number];

const interpreterAsSupportLanguage = (queryLanguage: string): SupportLanguage => {
    const supported: Locales = new Locales(supportLanguage);
    const locales: Locales = new Locales(queryLanguage);
    return locales.best(supported).toString() as SupportLanguage;
};

// zh の時には zh-TW にするという仕様。この仕様でいいのか知らんけど。
const interpretedLocale = (locale: string): SupportLanguage =>
    locale === "zh" ? interpreterAsSupportLanguage("zh-TW") : interpreterAsSupportLanguage(locale);

export const i18n = new VueI18n({
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(), // load from file
});

export const setLocale = (locale: string): void => {
    const safeLocale = interpretedLocale(locale);
    i18n.locale = safeLocale;
    dayjs.locale(safeLocale);
    const documentRoot = document.getElementsByTagName("html")[0];
    if (documentRoot) {
        documentRoot.lang = safeLocale;
    }
};

export const getLocaleForAPI = (): SupportLanguageForAPI => {
    return i18n.locale.replace("-", "_") as SupportLanguageForAPI;
};

export class I18nService {
    static translate(internationalization: I18nObject | undefined, targetLanguage: SupportLanguage): string {
        if (internationalization === undefined) {
            return "";
        }

        // resource resolution strategy based on: https://developer.android.com/guide/topics/resources/multilingual-support?hl=ja#localelist-api
        // see also: https://vacancorp.kibe.la/notes/612 by ryu.tamaki

        const translatedText: string | undefined = internationalization.languageList[targetLanguage];
        if (translatedText !== undefined) {
            return translatedText;
        }

        // try to resolve by parent language
        const shortenLanguage: string = targetLanguage.split("_")[0];

        const translatedTextByParentLanguage: string | undefined = internationalization.languageList[shortenLanguage];
        if (translatedTextByParentLanguage !== undefined) {
            return translatedTextByParentLanguage;
        }

        // try to resolve by child languages
        const translatedTextListByChildLanguageList: (string | undefined)[] = Object.keys(
            internationalization.languageList,
        )
            .filter((x: string) => x.substr(0, 2) === shortenLanguage) // find child language set in the StrictFormatSupportLanguage
            .map((childLanguage: string) => internationalization.languageList[childLanguage])
            .filter((x) => x !== undefined);

        if (translatedTextListByChildLanguageList.length !== 0) {
            if (translatedTextListByChildLanguageList[0] !== undefined) {
                return translatedTextListByChildLanguageList[0];
            }
        }

        // try to resolve by format targetLanguage "-" to "_"
        const targetLanguageFormattedToUnderscore: string = targetLanguage.replace("-", "_");
        const translatedTextByFormatToUnderscore: string | undefined =
            internationalization.languageList[targetLanguageFormattedToUnderscore];
        if (translatedTextByFormatToUnderscore !== undefined) {
            return translatedTextByFormatToUnderscore;
        }

        const fallbackText = internationalization.languageList[internationalization.fallbackLanguage];

        return fallbackText ?? "";
    }
}

export type I18nObject = {
    fallbackLanguage: string;
    languageList: { [P in Language | string]?: string };
};

type Language =
    | "af_ZA"
    | "am_ET"
    | "be_BY"
    | "bg_BG"
    | "ca_ES"
    | "cs_CZ"
    | "da_DK"
    | "de_AT"
    | "de_CH"
    | "de_DE"
    | "el_GR"
    | "en_AU"
    | "en_CA"
    | "en_GB"
    | "en_IE"
    | "en_NZ"
    | "en_US"
    | "es_ES"
    | "et_EE"
    | "eu_ES"
    | "fi_FI"
    | "fr_BE"
    | "fr_CA"
    | "fr_CH"
    | "fr_FR"
    | "he_IL"
    | "hi_IN"
    | "hr_HR"
    | "hu_HU"
    | "hy_AM"
    | "is_IS"
    | "it_CH"
    | "it_IT"
    | "ja_JP"
    | "kk_KZ"
    | "ko_KR"
    | "lt_LT"
    | "nl_BE"
    | "nl_NL"
    | "no_NO"
    | "pl_PL"
    | "pt_BR"
    | "pt_PT"
    | "ro_RO"
    | "ru_RU"
    | "sk_SK"
    | "sl_SI"
    | "sr_YU"
    | "sv_SE"
    | "tr_TR"
    | "uk_UA"
    | "zh_CN"
    | "zh_HK"
    | "zh_TW";

setLocale(browserLocale);
