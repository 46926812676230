
import Vue from "vue";
import QRCode from "qrcode";
import { isPC } from "@/utils/mobile";

import PlaceCoupon from "@/components/place/coupon.vue";
import ClosedMediaView from "@/components/closed-media.vue";
import CommonAnnouncementModal from "@/components/common-announcement-modal.vue";
import { Coupon, Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { ContentNotFoundError, WebsiteNotFoundError } from "@/utils/errors";
import router from "@/router";

export default Vue.extend({
  name: "views-coupons",
  components: {
    PlaceCoupon,
    ClosedMediaView,
    CommonAnnouncementModal,
  },
  props: {
    websiteId: { type: String, required: true },
    placeIdHash: { type: String, required: true },
  },
  data() {
    return {
      pageStatus: "",
      placeDetail: undefined as Place.PlaceDetail | undefined,
    };
  },
  computed: {
    couponList(): Coupon.Coupon[] {
      return this.placeDetail?.couponList ?? [];
    },
    isDesktop(): boolean {
      return isPC();
    },
  },
  async mounted() {
    this.pageStatus = "preparing";
    await this.setup();

    const manifestTag = document.querySelector("link[rel=manifest]");
    manifestTag?.setAttribute("href", `/api/v1/websites/${this.$route.params.websiteId}/manifests`);

    if (this.isDesktop) {
      this.drawQRCode();
    }
  },
  methods: {
    drawQRCode() {
      const currentURL = window.location.href;
      const canvas: HTMLElement | null = document.getElementById("coupon-qrcode");
      if (canvas === null) return;
      QRCode.toCanvas(canvas, currentURL, { width: 80, margin: 2 }, (error: unknown) => {
        if (error) console.error(`draw qrcode failed`);
      });
    },
    async setup(): Promise<void> {
      this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);

      const promise = this.placeDetail
        ? Promise.resolve()
        : this.$store
            .dispatch("fetchPlaceDetail", { websiteId: this.websiteId, placeIdHash: this.placeIdHash })
            .then(() => {
              this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);
            });

      return promise
        .then(async () => {
          if (this.placeDetail?.isAvailable === false) {
            this.pageStatus = "closed";
            return;
          }

          this.pageStatus = "ok";
        })
        .catch((error) => {
          if (error instanceof ContentNotFoundError) {
            router.replace({ name: "WebsiteError" });
            return;
          } else if (error instanceof WebsiteNotFoundError) {
            router.replace({ name: "Error" });
            return;
          }

          this.pageStatus = "error";
        });
    },
  },
});
