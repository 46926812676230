import { render, staticRenderFns } from "./closed-media.vue?vue&type=template&id=4131cacf&scoped=true&"
import script from "./closed-media.vue?vue&type=script&lang=ts&"
export * from "./closed-media.vue?vue&type=script&lang=ts&"
import style0 from "./closed-media.vue?vue&type=style&index=0&id=4131cacf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4131cacf",
  null
  
)

export default component.exports