
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "the-floating-footer",
  props: {
    relatedItemList: {
      type: Array as PropType<{ itemType: string; label: string; link?: string }[]>,
      required: true,
    },
  },
});
