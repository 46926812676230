//see https://app.asana.com/0/1200545856441699/1203268142129897/f

export interface PlaceGeo {
    latitude: number;
    longitude: number;
    url: string;
}

const geoProd: { [key: string]: PlaceGeo } = {
    yXPRZgrd: {
        latitude: 36.558911969108664,
        longitude: 137.72148514443245,
        url: "https://goo.gl/maps/5oEb1vM8tGJkqiDeA",
    },
    Q9VA0qX0: {
        latitude: 36.56009872767888,
        longitude: 137.72357484617206,
        url: "https://goo.gl/maps/U9Bcvm5GXCD3G7gr9",
    },
    pXGRQ1nM: {
        latitude: 36.56091140065631,
        longitude: 137.72644048184026,
        url: "https://goo.gl/maps/cfy2F3V2ZDCeVtvc8",
    },
};

const geoDev: { [key: string]: PlaceGeo } = {
    k9oYkMMn: {
        latitude: 36.558911969108664,
        longitude: 137.72148514443245,
        url: "https://goo.gl/maps/5oEb1vM8tGJkqiDeA",
    },
    "0XQw0NAr": {
        latitude: 36.56009872767888,
        longitude: 137.72357484617206,
        url: "https://goo.gl/maps/U9Bcvm5GXCD3G7gr9",
    },
    Gn3v52Dr: {
        latitude: 36.56091140065631,
        longitude: 137.72644048184026,
        url: "https://goo.gl/maps/cfy2F3V2ZDCeVtvc8",
    },
};

export const isOmachishi = (websiteId: string): boolean => {
    if (process.env.NODE_ENV === "development" && websiteId === "c1288874f1a14a") {
        return true;
    }
    return websiteId === "8056e6ba185449";
};

export const omachishiGeo = (websiteId: string, placeIdHash: string): PlaceGeo | undefined => {
    if (!isOmachishi(websiteId)) {
        return undefined;
    }
    if (
        process.env.VUE_APP_ENTERPRISE_VACAN_ADAPTER_API_BASE_URL ===
        "https://vshield.staging.vacan.com/pages.adapter.api"
    ) {
        return geoProd[placeIdHash];
    }
    const urls = process.env.NODE_ENV === "development" ? geoDev : geoProd;
    return urls[placeIdHash];
};
