
import MixinPlace from "@/components/place/mixin.vue";

import {
  Place as BackendPlace,
  Http,
  Ticket,
  Vacancy as BackendVacancy,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import ImageWithFallback from "@/components/image-with-fallback.vue";
import NotificationModal from "@/components/notification-modal.vue";
import ForecastChart from "@/components/place/forecast/forecast-chart.vue";

type Equipment = BackendPlace.Equipment;

export default MixinPlace.extend({
  name: "forecast-card",
  components: {
    ImageWithFallback,
    NotificationModal,
    ForecastChart,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
      default: undefined,
    },
    qticketConfig: {
      type: Object as () => (Ticket.TicketConfig & { placeIdHash: string }) | undefined,
      default: undefined,
    },
    forecast: {
      type: Object as () => { enabled: boolean; forecastList?: BackendVacancy.VacancyOfHour[] },
      default: () => ({ enabled: false, forecastList: undefined }),
    },
    history: {
      type: Array as () => BackendVacancy.VacancyOfHour[],
      default: () => [],
    },
    thresholds: {
      type: Object as () => BackendVacancy.VacancyStateThreshold,
      default: undefined,
    },
    isToday: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShowNotificationModal: false,
    };
  },
  computed: {
    shopClosing(): boolean {
      return (
        this.vacancy.status.state === "closed" ||
        this.vacancy.status.state === "lo" ||
        this.vacancy.status.state === "maintenance"
      );
    },
    locale(): string {
      return this.$i18n.locale;
    },
    forecastDataList(): number[] | undefined {
      return this.forecast.enabled && this.forecast.forecastList
        ? this.forecast.forecastList.map(
            (forecastData: BackendVacancy.VacancyOfHour) => forecastData.congestionPercent ?? -1,
          )
        : undefined;
    },
    historyDataList(): (number | undefined)[] {
      return this.isToday ? this.history.map((data) => data.congestionPercent) : [];
    },
    forecastNotFound(): boolean {
      return !this.forecastDataList;
    },
    forecastIsEmpty(): boolean {
      return !!this.forecastDataList && this.forecastDataList.every((value) => value <= 0);
    },
  },
});
