import { render, staticRenderFns } from "./omachishi-banner.vue?vue&type=template&id=0bb81db1&scoped=true&"
import script from "./omachishi-banner.vue?vue&type=script&lang=ts&"
export * from "./omachishi-banner.vue?vue&type=script&lang=ts&"
import style0 from "./omachishi-banner.vue?vue&type=style&index=0&id=0bb81db1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb81db1",
  null
  
)

export default component.exports