
import OmachishiBanner from "@/components/customizes/omachishi-banner.vue";
import ChichibushiBanner from "@/components/customizes/chichibushi-banner.vue";
import { isOmachishi } from "@/api/omachishi-customization";
import VButton from "@/components/v/button.vue";
import Vue from "vue";
import { TERMS_OF_PAGES_URL } from "@/utils/terms-of-service";

export default Vue.extend({
  name: "the-footer",
  components: {
    OmachishiBanner,
    ChichibushiBanner,
    VButton,
  },
  props: {
    theme: {
      type: String,
      required: true,
      default: "white",
      validator: (value: string) => ["white", "black", "dark-black", "place-black"].includes(value),
    },
    showTerms: { type: Boolean, default: false },
    termsUrl: { type: String, default: TERMS_OF_PAGES_URL },
    showContrastSettingButton: { type: Boolean, default: false },
    askInitialContrastSetting: { type: Boolean, default: false },
  },
  computed: {
    showOmachishiBanner(): boolean {
      return (
        !!this.$route.name && ["Index", "Place"].includes(this.$route.name) && isOmachishi(this.$route.params.websiteId)
      );
    },
    showChichibushiBanner(): boolean {
      // NOTE: 開発環境にはないけどゴメンナサイ（STGと本番のみ）
      const isChichibuShi = this.$route.params.websiteId === "9b442f3c83eb45";

      return !!this.$route.name && ["Index"].includes(this.$route.name) && isChichibuShi;
    },
  },
  methods: {
    openContrastSetting() {
      this.$emit("open-contrast-setting");
    },
    closeContrastSettingToast() {
      this.$emit("hide-contrast-setting-toast");
    },
  },
});
