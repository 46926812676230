import { render, staticRenderFns } from "./omachishi-congestion-modal.vue?vue&type=template&id=023bd538&scoped=true&"
import script from "./omachishi-congestion-modal.vue?vue&type=script&lang=ts&"
export * from "./omachishi-congestion-modal.vue?vue&type=script&lang=ts&"
import style0 from "./omachishi-congestion-modal.vue?vue&type=style&index=0&id=023bd538&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023bd538",
  null
  
)

export default component.exports