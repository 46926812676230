
import Vue, { PropType } from "vue";
import Icon from "@/components/v/icon.vue";
import VueSecureHTML from "vue-html-secure";
Vue.use(VueSecureHTML);

//copied from portal.vacanservice.com/src/util/util.ts and modified
export function urlify(text: string | undefined): string {
  if (text === undefined) return "";
  // Pagesの仕様になる
  let style = "text-decoration: none;"; //need this since vue doesn't know the inner <a> elements
  const urlRegex = /((https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))/g; // /(https?:\/\/[^\s?!,$]+)/g;
  let result: string = text.replace(urlRegex, `<a style="${style}" href="$1" target="_blank">$1</a>`);
  // match tel number const : /[0-9]{2,3}[-\s\.]?[0-9]{3,4}[-\s\.]?[0-9]{3,4}/g;
  const telRegex = /[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/g;
  const matched: string[] | null = text.match(telRegex);
  if (matched !== null) {
    for (const key in matched) {
      const match = matched[key];
      result = result.replace(match, `<a href="tel:${match}">${match}</a>`);
    }
  }
  return result;
}

interface Profile {
  title?: string;
  body?: string;
  togoUrl?: string;
  togo: boolean;
}

export default Vue.extend({
  name: "place-panel-promo",
  components: {
    Icon,
  },
  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
  },
  computed: {
    showPRDescriptionHTML(): string {
      if (this.profile.body === undefined) return "";
      return urlify(this.profile.body);
    },
  },
});
