
import Vue, { PropType } from "vue";
import { Bar } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, BarElement, LineController, LineElement, PointElement, CategoryScale, LinearScale);

export default Vue.extend({
  name: "v-forecast-chart",
  components: {
    Bar,
  },
  props: {
    forecastList: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    history: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    thresholds: {
      type: Object as PropType<{ crowded: number; busy: number; full: number }>,
      default: () => ({
        crowded: 60,
        busy: 80,
        full: 100,
      }),
    },
  },
  computed: {
    normalizedThresholds(): { crowded: number; busy: number; full: number } {
      return {
        crowded: this.thresholds.crowded || 60,
        busy: this.thresholds.busy > this.thresholds.crowded ? this.thresholds.busy : 80,
        full: this.thresholds.full > this.thresholds.busy ? this.thresholds.full : 100,
      };
    },
    chartOptions(): any {
      return {
        responsive: true,
        scales: {
          x: {
            ticks: {
              major: {
                enabled: false,
              },
              font: {
                size: 11,
              },
              autoSkip: false,
              maxRotation: 0,
            },
            stacked: true,
            border: {
              display: true,
            },
            grid: {
              offset: false,
              display: true,
              drawOnChartArea: false,
              drawTicks: true,
            },
          },
          y: {
            min: 0,
            max: this.normalizedThresholds.full,
            stacked: true,
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        animation: {
          duration: 0,
        },
      };
    },
    chartPlugins(): any {
      return [
        {
          beforeDraw: (chart: any) => {
            const ctx = chart.canvas.getContext("2d");
            if (ctx == null) {
              return;
            }
            const x = 4;
            const y = 0;
            const radius = 8;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "#F8F8FA";
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.arcTo(chart.width - x, y, chart.width - x, y + chart.height, radius);
            ctx.lineTo(chart.width - x, y + chart.height - 27);
            ctx.lineTo(x, y + chart.height - 27);
            ctx.arcTo(x, y, x + chart.width, y, radius);
            ctx.closePath();
            ctx.fill();
            ctx.restore();
          },
        },
      ];
    },
    chartData(): any {
      const pointRadius = Array((this.history as number[]).length)
        .fill(0)
        .map((_, index) => {
          return index === (this.history as number[]).length - 1 ? 3 : 0;
        });

      return {
        labels: [
          "6:00",
          "",
          "",
          "9",
          "",
          "",
          "12",
          "",
          "",
          "15",
          "",
          "",
          "18",
          "",
          "",
          "21",
          "",
          "",
          "0",
          "",
          "",
          "3",
          "",
          "",
        ],
        datasets: [
          {
            type: "bar",
            backgroundColor: "#1ec0b8",
            data: this.forecastList.map((value) => Math.min(value, this.normalizedThresholds.crowded)),
            order: 2,
            barPercentage: 1.1,
          },
          {
            type: "bar",
            backgroundColor: "#f6a623",
            data: this.forecastList.map((value) =>
              Math.min(
                Math.max(value - this.normalizedThresholds.crowded, 0),
                this.normalizedThresholds.busy - this.normalizedThresholds.crowded,
              ),
            ),
            order: 2,
            barPercentage: 1.1,
          },
          {
            type: "bar",
            backgroundColor: "#f1997f",
            data: this.forecastList.map((value) =>
              Math.min(
                Math.max(value - this.normalizedThresholds.busy, 0),
                this.normalizedThresholds.full - this.normalizedThresholds.busy,
              ),
            ),
            order: 2,
            barPercentage: 1.1,
          },
          {
            type: "line",
            data: this.history,
            order: 1,
            pointStyle: "circle",
            pointRadius,
            pointHoverRadius: pointRadius,
            backgroundColor: "#4e4e4e",
            borderColor: "#4e4e4e",
            borderWidth: 2,
            update: "none",
          },
        ],
      };
    },
  },
});
