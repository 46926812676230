
import Vue from "vue";
import { mapGetters } from "vuex";

import TheHeader from "@/components/the-header.vue";
import PlaceContainer from "@/components/place/multiple/container.vue";
import Questionnaire from "@/components/questionnaire.vue";
import FilterPanel from "@/components/place/filter/panel.vue";
import QuickSurvey from "@/components/quick-survey.vue";
import OmachishiCongestionModal from "@/components/customizes/omachishi-congestion-modal.vue";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
  Ticket,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { Category, CategoryOption, Area, FilterOptions } from "@/components/place/filter/filter.interface";

import { fetchTicketConfigByPlaceIdHash, fetchVacancyListByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
import { fetchResponseHotSpringValuesByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
import { isOmachishi } from "@/api/omachishi-customization";
import { ForecastState } from "@/store";
import dayjs from "dayjs";
import { LayoutType, LayoutTypeId } from "@/utils/layout";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotStringSetting = Http.ResponseHotSpringValues;

type ViewType = "vacancy" | "forecast";

interface DataPlaceMultipleIndex {
  vacancyList: Vacancy[];
  timer: { fetchingVacancies: number | undefined };
  hotSpringSettingList: HotStringSetting[];
  qticketConfigList: (Ticket.TicketConfig & { placeIdHash: string })[];
  selectedCategoryId: number | undefined;
  currentGenre: Category | undefined;
  rootGenre: Category | undefined;
  currentFloor: string | undefined;
  currentArea: Area | undefined;
  subCategoryList: Category[];
  lastFetchedVacancyUnixTime: number;
  showFilterPanel: boolean;
  filterOptions: FilterOptions;
  viewType: ViewType;
  showOmachishiModal: boolean;
}

export default Vue.extend({
  name: "multi-place-index",
  metaInfo() {
    const websiteName = this.website.name;
    return {
      title: websiteName,
      titleTemplate: this.$i18n.t("baseTitle").toString(),
    };
  },
  components: {
    TheHeader,
    PlaceContainer,
    Questionnaire,
    FilterPanel,
    QuickSurvey,
    OmachishiCongestionModal,
  },
  props: {
    placeList: { type: Array as () => Place[], default: () => [] },
    website: {
      type: Object as () => {
        id: string;
        name: string;
        questionnaireUrl?: string;
        theme: string;
        filtering: boolean;
        showQuickSurvey: boolean;
        layoutId: number;
        relatedItemList: { itemType: string; label: string; link?: string }[];
      },
      default: (): {
        id: string;
        name: string;
        questionnaireUrl?: string;
        theme: string;
        filtering: boolean;
        showQuickSurvey: boolean;
        layoutId: number;
        relatedItemList: { itemType: string; label: string; link?: string }[];
      } => ({
        id: "",
        name: "",
        questionnaireUrl: undefined,
        theme: "white",
        filtering: false,
        showQuickSurvey: false,
        layoutId: 1,
        relatedItemList: [],
      }),
    },
  },
  data(): DataPlaceMultipleIndex {
    return {
      vacancyList: [],
      timer: { fetchingVacancies: undefined },
      hotSpringSettingList: [],
      qticketConfigList: [],
      selectedCategoryId: undefined,
      currentGenre: undefined,
      rootGenre: undefined,
      currentFloor: undefined,
      currentArea: undefined,
      subCategoryList: [],
      lastFetchedVacancyUnixTime: new Date().getTime() / 1000,
      showFilterPanel: false,
      filterOptions: {
        status: {
          vacancy: false,
          qticket: false,
          opening: false,
        },
      },
      viewType: "vacancy",
      showOmachishiModal: false,
    };
  },
  computed: {
    ...(mapGetters({
      categoryList: "getCategoryList",
      floorList: "getFloors",
      areaList: "getAreaList",
      forecastState: "getForecast",
      layoutType: "getLayoutType",
    }) as {
      categoryList: () => CategoryOption[];
      floorList: () => string[];
      areaList: () => Area[];
      forecastState: () => ForecastState;
      layoutType: () => LayoutTypeId;
    }),
    placeIdHashList(): string[] {
      return this.placeList.map((place: Place) => place.placeIdHash);
    },
    sortedFloorList(): string[] {
      // drop 'no floor'
      const floorList = [...this.floorList.filter((floor) => !!floor)];

      return floorList.sort((a, b) => {
        const first = a.replace("B", "-").replace("F", "");
        const second = b.replace("B", "-").replace("F", "");
        return parseInt(first) - parseInt(second);
      });
    },
    sortedAreaList(): Area[] {
      return this.areaList.slice().sort((a, b) => a.areaId - b.areaId);
    },
    isFilterApplying(): boolean {
      if (Object.values(this.filterOptions.status).some((useFilter) => useFilter)) {
        return true;
      }

      return (
        !!this.filterOptions.genreId ||
        (this.filterOptions.categoryIdList?.length ?? 0) > 0 ||
        (this.filterOptions.floorList?.length ?? 0) > 0 ||
        (this.filterOptions.areaIdList?.length ?? 0) > 0
      );
    },
    isExistForecast(): boolean {
      return this.forecastState.enabledPlaceList.length > 0;
    },
    forecastIsToday(): boolean {
      const now = dayjs().format("YYYYMMDD");
      return now === this.forecastState.selectedDate;
    },
    customizeForOmachishi(): boolean {
      return isOmachishi(this.website.id);
    },
    showQuestionnaire(): boolean {
      return this.layoutType === LayoutType.Default && !!this.website.questionnaireUrl;
    },
  },
  async created() {
    await this.setup();
    this.loop();
    window.addEventListener("scroll", this.lazyLoad);
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      try {
        if (this.placeIdHashList.length <= 0) {
          this.lastFetchedVacancyUnixTime = new Date().getTime();
          return;
        }
        this.vacancyList = await fetchVacancyListByPlaceIdHashList(this.placeIdHashList);
        this.lastFetchedVacancyUnixTime = new Date().getTime();
        this.$store.commit("setAlertView", {
          status: false,
        });

        this.hotSpringSettingList = (await fetchResponseHotSpringValuesByPlaceIdHashList(this.placeIdHashList)) ?? [];

        const qticketEnabledPlaceList = this.placeList.filter((place) => place.featureList.includes("qticket"));
        this.qticketConfigList = await Promise.all(
          qticketEnabledPlaceList.map((place) =>
            fetchTicketConfigByPlaceIdHash(place.placeIdHash).then((config) => ({
              placeIdHash: place.placeIdHash,
              ...config,
            })),
          ),
        );

        if (this.forecastState.enabledPlaceList.length > 0) {
          this.$store.dispatch("fetchWeekdayList");
        }
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60 * 1000;
        const currentUnixTime = new Date().getTime();

        if (currentUnixTime >= this.lastFetchedVacancyUnixTime + NETWORK_ERROR_THRESHOLD) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    loop(): void {
      this.timer.fetchingVacancies = window.setTimeout(async () => {
        await this.setup();
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }
      window.removeEventListener("scroll", this.lazyLoad);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openFilterPanel() {
      this.showFilterPanel = true;
      document.documentElement.style.overflow = "hidden";
    },
    closeFilterPanel() {
      this.showFilterPanel = false;
      setTimeout(() => {
        // show scroll bar again after transition end
        document.documentElement.style.overflow = "visible";
      }, 400);
    },
    async applyFilter(options: FilterOptions) {
      this.filterOptions = options;

      await this.$store.dispatch("fetchFilteredPlaces", {
        websiteId: this.$route.params.websiteId,
        offset: 0,
        limit: 30,
        categoryIdList:
          this.filterOptions.categoryIdList?.length ?? 0 > 0
            ? this.filterOptions.categoryIdList
            : [this.filterOptions.genreId],
        floorList: this.filterOptions.floorList,
        areaIdList: this.filterOptions.areaIdList,
        status: this.filterOptions.status,
      });

      this.scrollTop();
      this.closeFilterPanel();
    },
    async lazyLoad() {
      const rootElement: HTMLElement | null = document.querySelector(".component-index-multiple");
      if (!rootElement) {
        return;
      }
      // 65 for the height of the footer
      const bottomOfWindow = window.scrollY + window.innerHeight >= rootElement.offsetHeight - 65;
      if (bottomOfWindow) {
        await this.$store.dispatch("fetchMorePlaces", {
          websiteId: this.$route.params.websiteId,
          offset: this.placeList.length,
          limit: 10,
          categoryIdList:
            this.filterOptions.categoryIdList?.length ?? 0 > 0
              ? this.filterOptions.categoryIdList
              : [this.filterOptions.genreId],
          floorList: this.filterOptions.floorList,
          areaIdList: this.filterOptions.areaIdList,
          status: this.filterOptions.status,
        });
      }
    },
    onChangeViewType(viewType: ViewType) {
      this.viewType = viewType;
    },
    onDateSelected(date: string) {
      this.$store.commit("setSelectedDate", date);
    },
  },
});
