
import Vue from "vue";
import VButton from "@/components/v/button.vue";

export default Vue.extend({
  name: "omachishi-congestion-modal",
  components: {
    VButton,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
