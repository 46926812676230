
import Vue from "vue";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Ticket,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import PlaceCard from "@/components/place/multiple/card.vue";
import ForecastCard from "@/components/place/forecast/card.vue";
import { isOmachishi } from "@/api/omachishi-customization";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

export default Vue.extend({
  name: "place-container",
  components: {
    PlaceCard,
    ForecastCard,
  },
  props: {
    placeList: { type: Array as () => Place[], required: true, default: (): Place[] => [] },
    vacancyList: { type: Array as () => Vacancy[], required: true, default: (): Vacancy[] => [] },
    hotSpringSettingList: {
      type: Array as () => HotSpringSetting[],
      required: true,
      default: (): HotSpringSetting[] => [],
    },
    qticketConfigList: {
      type: Array as () => (Ticket.TicketConfig & { placeIdHash: string })[],
      default: (): (Ticket.TicketConfig & { placeIdHash: string })[] => [],
    },
    viewType: { type: String as () => "vacancy" | "forecast", default: "vacancy" },
    forecastEnabledPlaceList: { type: Array as () => string[], default: () => [] },
    forecastDictionary: {
      type: Object as () => { [id: string]: BackendVacancy.VacancyOfHour[] },
      default: () => ({}),
    },
    vacancyHistory: {
      type: Object as () => { [id: string]: BackendVacancy.VacancyOfHour[] },
      default: () => ({}),
    },
    thresholdDictionary: {
      type: Object as () => Record<string, BackendVacancy.VacancyStateThreshold>,
      default: () => ({} as Record<string, BackendVacancy.VacancyStateThreshold>),
    },
    forecastIsToday: {
      type: Boolean,
    },
    websiteId: {
      type: String,
      default: "",
    },
  },
  computed: {
    vacancyByPlaceIdHash(): (placeIdHash: string) => Vacancy | undefined {
      return (placeIdHash: string) => this.vacancyList.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);
    },
    hotSpringSettingByPlaceIdHash(): (placeIdHash: string) => HotSpringSetting | undefined {
      return (placeIdHash: string) =>
        this.hotSpringSettingList.find(
          (hotSpringSetting: HotSpringSetting) => hotSpringSetting.placeIdHash === placeIdHash,
        );
    },
    qticketConfigByPlaceIdHash(): (placeIdHash: string) => (Ticket.TicketConfig & { placeIdHash: string }) | undefined {
      return (placeIdHash: string) => this.qticketConfigList.find((config) => config.placeIdHash === placeIdHash);
    },
    forecastByPlaceIdHash(): (placeIdHash: string) => {
      enabled: boolean;
      forecastList?: BackendVacancy.VacancyOfHour[];
    } {
      return (placeIdHash: string) => {
        if (this.forecastEnabledPlaceList.find((enabled) => enabled === placeIdHash)) {
          return {
            enabled: true,
            forecastList: (this.forecastDictionary as { [id: string]: BackendVacancy.VacancyOfHour[] })[placeIdHash],
          };
        }

        return { enabled: false };
      };
    },
    vacancyHistoryByPlaceIdHash(): (placeIdHash: string) => BackendVacancy.VacancyOfHour[] {
      return (placeIdHash: string) => {
        return (this.vacancyHistory as { [id: string]: BackendVacancy.VacancyOfHour[] })[placeIdHash];
      };
    },
    vacancyStateThresholdByPlaceIdHash(): (placeIdHash: string) => BackendVacancy.VacancyStateThreshold | undefined {
      return (placeIdHash: string) => {
        return this.thresholdDictionary[placeIdHash];
      };
    },
    customizeForOmachi(): boolean {
      return isOmachishi(this.websiteId);
    },
  },
});
