import {
    fetchFilteredPlaceListByWebsiteId,
    fetchForecast,
    fetchPlaceDetailByPlaceIdHash,
    fetchPlaceFilterOptions,
    fetchUpdatedFilters,
    fetchVacancyHistory,
    fetchWeekdayList,
    fetchJrWestVacancyPrediction as fetchJrWestVacancyPredictionApi,
    fetchVacancyStateThresholdListByPlaceIdHashList,
} from "@/api/enterprise-vacan.adapter.api";
import { LayoutType, LayoutTypeId } from "@/utils/layout";
import { Place, Vacancy, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import dayjs from "dayjs";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";

Vue.use(Vuex);
interface Website {
    id: string;
    name: string;
    questionnaireUrl: string;
    theme: string;
    filtering: boolean;
    showQuickSurvey: boolean;
    layoutId: number;
    relatedItemList: Http.RelatedItem[];
}

export type Weekday = {
    date: string;
    unixtime: number;
    isWorkday: boolean;
};

export interface ForecastState {
    enabledPlaceList: string[];
    weekdayList: Weekday[];
    selectedDate: string;
    forecastDictionary: {
        [date: string]: {
            [placeIdHash: string]: Vacancy.VacancyOfHour[];
        };
    };
    vacancyHistory: {
        [placeIdHash: string]: Vacancy.VacancyOfHour[];
    };
    vacancyStateThresholdDictionary: {
        [placeIdHash: string]: Vacancy.VacancyStateThreshold;
    };
}

export interface Prediction {
    time: string;
    status: string;
}

type Category = {
    categoryId: number;
    categoryName: string;
};
type CategoryOption = {
    rootCategory: Category;
    subCategoryList: Category[];
};
type Area = {
    areaId: number;
    areaName: string;
};

type AlertView = {
    status: boolean;
    msg: string;
    btnTitle: string;
};

interface PlaceWithProfile extends Place.Place {
    profile?: Profile;
}

interface Profile {
    title?: string;
    body?: string;
    togo: boolean;
    togoUrl?: string;
    direction: string;
}

interface AppState {
    website: Website;
    placeList: PlaceWithProfile[];
    placeDetail: Record<string, Place.PlaceDetail>;
    categoryList: CategoryOption[];
    floorList: string[];
    areaList: Area[];
    defaultCategoryList: CategoryOption[];
    defaultFloorList: string[];
    defaultAreaList: Area[];
    requestedOffset: Set<number>;
    alertView: AlertView;
    forecast: ForecastState;
    // for devtools
    overridedTheme?: string;
    predictionList: Prediction[];
}

const state: AppState = {
    website: {
        id: "",
        name: "",
        questionnaireUrl: "",
        theme: "white",
        filtering: false,
        showQuickSurvey: false,
        layoutId: 1,
        relatedItemList: [],
    },
    placeList: [],
    placeDetail: {},
    categoryList: [],
    floorList: [],
    areaList: [],
    defaultCategoryList: [],
    defaultFloorList: [],
    defaultAreaList: [],
    requestedOffset: new Set<number>(),
    alertView: {
        status: false,
        msg: "",
        btnTitle: "",
    },
    forecast: {
        enabledPlaceList: [],
        weekdayList: [],
        selectedDate: "",
        forecastDictionary: {},
        vacancyHistory: {},
        vacancyStateThresholdDictionary: {},
    },
    overridedTheme: undefined,
    predictionList: [],
};

const mutations: MutationTree<AppState> = {
    setWebsite(state: AppState, website: Website) {
        state.website = website;
    },
    setPlaceList(state: AppState, placeList: PlaceWithProfile[]) {
        state.placeList = placeList;
        state.forecast.enabledPlaceList = placeList
            .filter((place) => place.displaySetting.enableCongestionForecast)
            .map((place) => place.placeIdHash);
    },
    setPlaceDetail(state: AppState, placeDetail: Place.PlaceDetail) {
        state.placeDetail[placeDetail.placeIdHash] = placeDetail;
    },
    setAdditionalPlaces(state: AppState, placeList: Place.Place[]) {
        if (placeList.length) {
            state.placeList = [...state.placeList, ...placeList];
            state.forecast = { ...state.forecast };
            state.forecast.enabledPlaceList = [
                ...state.forecast.enabledPlaceList,
                ...placeList
                    .filter((place) => place.displaySetting.enableCongestionForecast)
                    .map((place) => place.placeIdHash),
            ];
        }
    },

    setCategoryList(state: AppState, categoryList: CategoryOption[]) {
        state.categoryList = categoryList;
    },
    setFloorList(state: AppState, floorList: string[]) {
        state.floorList = floorList;
    },
    setAreaList(state: AppState, areaList: Area[]) {
        state.areaList = areaList;
    },

    setDefaultFloorList(state: AppState, floorList: string[]) {
        state.defaultFloorList = floorList;
    },
    setDefaultCategoryList(state: AppState, categoryList: CategoryOption[]) {
        state.defaultCategoryList = categoryList;
    },
    setDefaultAreaList(state: AppState, areaList: Area[]) {
        state.defaultAreaList = areaList;
    },

    setPageTheme(state: AppState, theme: string) {
        state.website.theme = theme;
    },
    overrideTheme(state: AppState, theme: string) {
        state.overridedTheme = theme;
    },
    setAlertView: (
        state: AppState,
        payload: {
            status: boolean;
            msg: string;
            btnTitle: string;
        },
    ) => {
        state.alertView = payload;
    },

    setWeekdayList: (state: AppState, payload: Place.Weekday[]) => {
        state.forecast = {
            ...state.forecast,
            weekdayList: payload.map((weekday) => ({
                ...weekday,
                date: dayjs.unix(weekday.unixtime).format("YYYYMMDD"),
            })),
        };

        if (!state.forecast.selectedDate || state.forecast.selectedDate < state.forecast.weekdayList[0].date) {
            state.forecast = { ...state.forecast, selectedDate: state.forecast.weekdayList[0].date };
        }
    },
    setForecast: (
        state: AppState,
        payload: {
            date: string;
            placeForecastList: {
                placeIdHash: string;
                forecastList: Vacancy.VacancyOfHour[];
            }[];
        },
    ) => {
        state.forecast = {
            ...state.forecast,
            forecastDictionary: {
                ...state.forecast.forecastDictionary,
                [payload.date]: state.forecast.forecastDictionary[payload.date] ?? {},
            },
        };
        payload.placeForecastList.forEach((placeForecast) => {
            const sorted = placeForecast.forecastList.sort((a, b) => a.hour - b.hour);
            const nextDay: Vacancy.VacancyOfHour[] = [];
            while (sorted.length > 0 && sorted[0].hour < 6) {
                const top = sorted.shift();
                if (top == null) {
                    continue;
                }
                nextDay.push(top);
            }

            state.forecast.forecastDictionary[payload.date][placeForecast.placeIdHash] = sorted.concat(nextDay);
        });
    },
    setVacancyHistory: (state: AppState, payload: { placeIdHash: string; history: Vacancy.VacancyOfHour[] }[]) => {
        state.forecast = { ...state.forecast, vacancyHistory: {} };
        payload.forEach((history) => {
            const sorted = history.history.sort((a, b) => a.hour - b.hour);
            const nextDay: Vacancy.VacancyOfHour[] = [];
            while (sorted.length > 0 && sorted[0].hour < 6) {
                const top = sorted.shift();
                if (top == null) {
                    continue;
                }
                nextDay.push(top);
            }

            state.forecast.vacancyHistory[history.placeIdHash] = sorted.concat(nextDay);
        });
    },
    setVacancyStateThreshold: (state: AppState, payload: Vacancy.VacancyStateThreshold[]) => {
        payload.forEach((threshold) => {
            state.forecast.vacancyStateThresholdDictionary[threshold.placeIdHash] = threshold;
        });
    },
    setSelectedDate: (state: AppState, payload: string) => {
        state.forecast = { ...state.forecast, selectedDate: payload };
    },
    setJrWestPrediction: (state: AppState, predictionList: { time: string; status: string }[]) => {
        state.predictionList = predictionList;
    },
};

const getters: GetterTree<AppState, AppState> = {
    getWebsite(state: AppState) {
        return state.website;
    },
    getTheme(state: AppState) {
        return state.overridedTheme ?? state.website.theme;
    },
    getLayoutType(state: AppState): LayoutTypeId {
        const layoutId = state.website.layoutId;
        const isLayoutTypeId = (id: number): id is LayoutTypeId => Object.values<number>(LayoutType).includes(id);

        return isLayoutTypeId(layoutId) ? layoutId : LayoutType.Default;
    },
    getPlaceList(state: AppState) {
        return state.placeList;
    },
    getPlaceDetail(state: AppState) {
        return (placeIdHash: string): Place.PlaceDetail | undefined => state.placeDetail[placeIdHash];
    },
    getCategoryList(state: AppState) {
        return state.categoryList;
    },
    getFloors(state: AppState) {
        return state.floorList;
    },
    getAreaList(state: AppState) {
        return state.areaList;
    },
    getForecast(state: AppState) {
        return state.forecast;
    },
    getJrWestPrediction(state: AppState) {
        return state.predictionList;
    },
};

const actions: ActionTree<AppState, AppState> = {
    async fetchWebsite({ commit }, websiteId: string) {
        const placeList = await fetchFilteredPlaceListByWebsiteId(websiteId);
        if (!placeList) {
            return;
        }
        commit("setWebsite", {
            id: websiteId,
            name: placeList.websiteTitle.name,
            questionnaireUrl: placeList.questionnaireUrl || "",
            theme: placeList.theme || "white",
            filtering: placeList.filtering,
            isAvailable: placeList.isAvailable ?? true,
            showQuickSurvey: placeList.showQuickSurvey,
            layoutId: placeList.layoutId,
            relatedItemList: placeList.relatedItemList,
        });
        commit(
            "setPlaceList",
            placeList.placeList.map((p) => {
                return {
                    ...p,
                    profile: p.profile
                        ? {
                              title: p.profile.prTitle,
                              body: p.profile.prText,
                              togo: p.profile.canTakeout,
                              togoUrl: p.profile.takeoutUrl,
                              direction: p.profile.accessInformation,
                          }
                        : undefined,
                };
            }),
        );

        if (placeList.filtering) {
            const filterOptions = await fetchPlaceFilterOptions(websiteId);
            if (!filterOptions) {
                return;
            }
            commit("setFloorList", filterOptions.floorList);
            commit("setDefaultFloorList", filterOptions.floorList);
            commit("setCategoryList", filterOptions.categoryList);
            commit("setDefaultCategoryList", filterOptions.categoryList);
            commit("setAreaList", filterOptions.areaList);
            commit("setDefaultAreaList", filterOptions.areaList);
        }
    },
    async fetchPlaceDetail({ commit, state }, params: { websiteId: string; placeIdHash: string }) {
        const placeDetail = await fetchPlaceDetailByPlaceIdHash(params.websiteId, params.placeIdHash);
        commit("setPlaceDetail", placeDetail);
        commit("setPageTheme", placeDetail.theme);

        if (state.placeList.length <= 0) {
            commit("setPlaceList", [placeDetail]);
        }
    },

    // Used after the user selects some filters
    async fetchFilteredPlaces(
        { commit },
        {
            websiteId,
            offset,
            limit = 10,
            categoryIdList,
            floorList,
            areaIdList,
            status,
        }: {
            websiteId: string;
            offset: number;
            limit: number;
            categoryIdList?: number[];
            floorList?: string[];
            areaIdList?: number[];
            status: {
                vacancy: boolean;
                qticket: boolean;
                opening: boolean;
            };
        },
    ) {
        const requestVacancyList = [];
        if (status.vacancy) {
            requestVacancyList.push("vacancy");
            requestVacancyList.push("unknown");
        }
        if (status.opening) {
            requestVacancyList.push("opening");
        }
        const placeList = await fetchFilteredPlaceListByWebsiteId(
            websiteId,
            offset,
            limit,
            categoryIdList,
            floorList,
            areaIdList,
            status.qticket ? ["qticket"] : undefined,
            requestVacancyList,
        );
        if (placeList !== undefined) {
            commit("setPlaceList", placeList.placeList);
        }
        // reset requestOffset
        state.requestedOffset = new Set<number>();
    },
    // Used on scroll;
    async fetchMorePlaces(
        { commit },
        {
            websiteId,
            offset,
            limit = 10,
            categoryIdList,
            floorList,
            areaIdList,
            status,
        }: {
            websiteId: string;
            offset: number;
            limit: number;
            categoryIdList?: number[];
            floorList?: string[];
            areaIdList?: number[];
            status: {
                vacancy: boolean;
                qticket: boolean;
                opening: boolean;
            };
        },
    ) {
        // Don't request the same things more than once
        if (!state.requestedOffset.has(offset)) {
            state.requestedOffset.add(offset);
            const requestVacancyList = [];
            if (status.vacancy) {
                requestVacancyList.push("vacancy");
                requestVacancyList.push("unknown");
            }
            if (status.opening) {
                requestVacancyList.push("opening");
            }
            const placeList = await fetchFilteredPlaceListByWebsiteId(
                websiteId,
                offset,
                limit,
                categoryIdList,
                floorList,
                areaIdList,
                status.qticket ? ["qticket"] : undefined,
                requestVacancyList,
            );
            if (placeList !== undefined && placeList.placeList.length > 0) {
                commit("setAdditionalPlaces", placeList.placeList);
            }
        }
    },
    async updateFilters(
        { commit },
        {
            isCategorySelected,
            isFloorSelected,
            isAreaSelected,
        }: {
            isCategorySelected?: boolean;
            isFloorSelected?: boolean;
            isAreaSelected?: boolean;
        },
    ) {
        const placeIdHashList = this.state.placeList.map((place) => place.placeIdHash);
        const filterOptions = await fetchUpdatedFilters(placeIdHashList);
        if (!filterOptions) {
            return;
        }

        commit(
            "setCategoryList",
            isCategorySelected && !isFloorSelected && !isAreaSelected
                ? state.defaultCategoryList
                : filterOptions.categoryList,
        );
        commit(
            "setFloorList",
            isFloorSelected && !isCategorySelected && !isAreaSelected
                ? state.defaultFloorList
                : filterOptions.floorList,
        );
        commit(
            "setAreaList",
            isAreaSelected && !isCategorySelected && !isFloorSelected ? state.defaultAreaList : filterOptions.areaList,
        );

        // refresh requestOffset so you can fetch the correct ones again
        state.requestedOffset = new Set<number>();
    },

    async setDefaultFilterOptions({ commit }) {
        commit("setFloorList", state.defaultFloorList);
        commit("setCategoryList", state.defaultCategoryList);
        commit("setAreaList", state.defaultAreaList);
    },

    async fetchWeekdayList({ commit, dispatch, state }) {
        if (
            !state.forecast.weekdayList[0] ||
            !dayjs.unix(state.forecast.weekdayList[0].unixtime).isSame(dayjs(), "date")
        ) {
            commit("setWeekdayList", await fetchWeekdayList());
        }

        dispatch("fetchForecast");
        dispatch("fetchVacancyHistory");
        dispatch("fetchVacancyStateThreshold");
    },
    async fetchJrWestVacancyPrediction({ commit }, { point }: { point: number }) {
        const predictionList = await fetchJrWestVacancyPredictionApi(point);
        commit("setJrWestPrediction", predictionList);
    },
    async fetchForecast({ commit, state }) {
        for (const day of state.forecast.weekdayList) {
            const needUpdatePlaceList = state.placeList.filter(
                (place) =>
                    !state.forecast.forecastDictionary[day.date] ||
                    !state.forecast.forecastDictionary[day.date][place.placeIdHash],
            );

            if (needUpdatePlaceList.length > 0) {
                const forecast = await fetchForecast(
                    needUpdatePlaceList.map((place) => place.placeIdHash),
                    day.unixtime,
                );
                commit("setForecast", { date: day.date, placeForecastList: forecast.placeForecastList });
            }
        }
    },
    async fetchVacancyHistory({ commit, state }) {
        const targets = state.placeList.map((place) => place.placeIdHash);

        if (targets.every((placeIdHash) => state.forecast.vacancyHistory[placeIdHash] !== undefined)) {
            return;
        }

        const historyList = await fetchVacancyHistory(targets);
        commit("setVacancyHistory", historyList);
    },
    async fetchVacancyStateThreshold({ commit, state }) {
        const needUpdatePlaceList = state.forecast.enabledPlaceList.filter(
            (placeIdHash) => state.forecast.vacancyStateThresholdDictionary[placeIdHash] === undefined,
        );

        if (needUpdatePlaceList.length <= 0) {
            return;
        }

        const vacancyStateThresholdList = await fetchVacancyStateThresholdListByPlaceIdHashList(needUpdatePlaceList);
        commit("setVacancyStateThreshold", vacancyStateThresholdList);
    },
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {},
});
