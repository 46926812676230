
import Vue, { PropType } from "vue";

import { Place as BackendPlace } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

type Equipment = BackendPlace.Equipment;

interface Profile {
  direction: string;
}

export default Vue.extend({
  name: "place-panel-basic",
  props: {
    tel: { type: String, required: false, default: undefined },
    address: { type: String, required: false, default: undefined },
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    regularHolidayList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], default: (): Equipment[] => [] },
    remark: { type: String, required: false, default: undefined },
    isDisplayBusinessHour: { type: Boolean, default: true },
    isDisplayRegularHoliday: { type: Boolean, default: true },
    isDisplayContactMessage: { type: Boolean, default: true },
    profile: { type: Object as PropType<Profile | undefined>, required: true },
    omachishiLink: { type: String, default: undefined },
  },
  computed: {
    isSomeInformationExists(): boolean {
      return (
        this.showTel ||
        this.showAddress ||
        this.showOpeningHoursList ||
        this.showRegularHolidayList ||
        this.showEquipmentList ||
        this.showRemark
      );
    },
    showTel(): boolean {
      return this.tel !== undefined && this.tel.length > 0;
    },
    showContactMessage(): boolean {
      return this.isDisplayContactMessage;
    },
    showAddress(): boolean {
      return this.address !== undefined && this.address.length > 0;
    },
    showOpeningHoursList(): boolean {
      return this.isDisplayBusinessHour && this.openingHoursList.length > 0;
    },
    showRegularHolidayList(): boolean {
      return this.isDisplayRegularHoliday && this.regularHolidayList.length > 0;
    },
    showEquipmentList(): boolean {
      return this.equipmentList.length > 0;
    },
    showRemark(): boolean {
      return this.remarkWithParsedUrl.length > 0;
    },
    displayEquipmentText(): string {
      if (!this.showEquipmentList) {
        return "";
      }
      return this.equipmentList
        .map((equipment: Equipment) => {
          switch (equipment.equipmentType) {
            case "power":
            case "wifi":
              return equipment.displayText;
            case "nonSmokingSeatCapacity":
            case "smokingSeatCapacity":
            case "totalSeatingCapacity":
              return this.$i18n.t(`place.basic.equipmentsItemTemp.${equipment.equipmentType}`, {
                count: equipment.displayText,
              });
            default:
              return undefined;
          }
        })
        .filter((text): text is string => text !== undefined)
        .join("、");
    },
    remarkWithParsedUrl(): string {
      if (this.remark === undefined) {
        return "";
      }
      const regexpUrl = /((https)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))/g;
      return this.remark.replace(regexpUrl, "<a href='$1' target='_blank' style='color:var(--link-color)'>$1</a>");
    },
  },
});
