var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"views-places"},[(_vm.placeDetail !== undefined && _vm.placeDetail.imageUrlList.length > 0)?_c('place-panel-image',{staticClass:"panel image",attrs:{"src-list":_vm.placeDetail.imageUrlList,"website-id":_vm.websiteId,"place-id-hash":_vm.placeIdHash}}):_vm._e(),(_vm.placeDetail !== undefined && _vm.vacancy !== undefined)?_c('place-panel-summary',{staticClass:"panel summary",attrs:{"name":_vm.placeDetail.name,"floor":_vm.placeDetail.floor,"area":_vm.placeDetail.area !== undefined ? _vm.placeDetail.area.areaName : undefined,"vacancy-status":_vm.vacancy.status.state,"vacancy-text":_vm.vacancy.status.text,"count-text":_vm.vacancy.status.countText,"vacancy-style":_vm.placeDetail.displaySetting.vacancyStyle,"opening-hours-list":_vm.placeDetail.openingHoursList,"equipment-list":_vm.placeDetail.equipmentList,"feature-list":_vm.placeDetail.featureList,"category-list":_vm.placeDetail.categoryList,"has-coupon":_vm.placeDetail.couponList.length > 0,"qticket-status":_vm.vacancy.qticketStatus,"qticket-config":_vm.qticketConfig,"hot-spring-opening-hours-list":_vm.openingHourStringList,"current-gender":_vm.currentGender,"is-display-business-hour":_vm.showBusinessHour,"place-id-hash":_vm.placeIdHash,"show-forecast":_vm.placeDetail.displaySetting.enableCongestionForecast,"forecast-state":_vm.forecastState,"profile":_vm.profile},on:{"open-qticket":function($event){return _vm.$router.push({
        name: 'QT/Entry',
        params: { websiteId: _vm.websiteId, placeIdHash: _vm.placeIdHash },
        query: {
          'skip-tutorial': _vm.qticketConfig !== undefined && _vm.qticketConfig.disableNotification ? true : undefined,
        },
      })},"open-coupons":function($event){return _vm.$router.push({ name: 'Coupons', params: { websiteId: _vm.websiteId, placeIdHash: _vm.placeIdHash } })}}}):_vm._e(),(_vm.placeDetail !== undefined && _vm.placeDetail.basic !== undefined)?_c('place-panel-basic',{staticClass:"panel basic",attrs:{"tel":_vm.placeDetail.basic.tel,"address":_vm.placeDetail.basic.address,"opening-hours-list":_vm.placeDetail.openingHoursList,"regular-holiday-list":_vm.placeDetail.basic.regularHolidayList,"equipment-list":_vm.placeDetail.equipmentList,"remark":_vm.placeDetail.basic.remark,"is-display-business-hour":_vm.showBusinessHour,"is-display-regular-holiday":_vm.showRegularHoliday,"is-display-contact-message":_vm.showContactMessage,"profile":_vm.profile}}):_vm._e(),(_vm.placeDetail !== undefined && _vm.placeDetail.media !== undefined)?_c('place-panel-media',{staticClass:"panel media",attrs:{"website-url":_vm.placeDetail.media.officialSiteUrl,"facebook-url":_vm.placeDetail.media.socialMediaUrl.facebook,"twitter-url":_vm.placeDetail.media.socialMediaUrl.twitter,"line-url":_vm.placeDetail.media.socialMediaUrl.line,"instagram-url":_vm.placeDetail.media.socialMediaUrl.instagram}}):_vm._e(),(_vm.showQuickSurvey)?_c('quick-survey',{attrs:{"website-id":_vm.websiteId,"theme":_vm.theme}}):_vm._e(),(_vm.showQuestionnaire && _vm.placeDetail !== undefined)?_c('questionnaire',{staticClass:"questionnaire",attrs:{"url":_vm.questionnaireUrl,"label":_vm.placeDetail.name}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }