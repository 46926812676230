
import Vue from "vue";

// components
import DateSelectorButton from "@/components/place/forecast/date-selector-button.vue";

type Weekday = {
  date: string;
  unixtime: number;
  isWorkday: boolean;
};

export default Vue.extend({
  name: "v-date-selector",
  components: {
    DateSelectorButton,
  },
  props: {
    weekdayList: { type: Array as () => Weekday[], default: () => [] },
    selectedDate: { type: String, default: "" },
    fixDaysToTop: { type: Boolean, default: false },
  },
  methods: {
    isSelected(day: Weekday) {
      return day.date === this.selectedDate;
    },
    onDateSelected(datetime: number) {
      this.$emit("select", datetime);
    },
  },
});
