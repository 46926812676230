
import Vue from "vue";

import TheFooter from "@/components/the-footer.vue";
import TheFloatingFooter from "@/components/the-floating-footer.vue";
import { mapGetters } from "vuex";
import ColorContrastSettingModal from "./components/color-contrast-setting-modal.vue";
import * as storage from "@/api/local-storage";
import { LayoutType } from "./utils/layout";
import { TERMS_OF_QT_PATH, TERMS_OF_PAGES_URL } from "./utils/terms-of-service";

export default Vue.extend({
  name: "app",
  components: {
    TheFooter,
    TheFloatingFooter,
    ColorContrastSettingModal,
  },
  data() {
    return {
      currentTheme: "white",
      showContrastSetting: false,
      contrastSetting: "normal",
      askInitialContrastSetting: false,
    };
  },
  computed: {
    ...mapGetters({
      theme: "getTheme",
      layoutType: "getLayoutType",
      website: "getWebsite",
    }),
    isBlackTheme(): boolean {
      return this.theme === "black";
    },
    termsUrl(): string | undefined {
      const nolineTermsRouteList = [/QT\/*/];

      const useNolineTerms = nolineTermsRouteList.reduce((result, route) => {
        return result || route.test(this.$route.name || "");
      }, false);

      return useNolineTerms ? TERMS_OF_QT_PATH : TERMS_OF_PAGES_URL;
    },
    enableDevTools(): boolean {
      return process.env.NODE_ENV === "development";
    },
    /**
     * Remove this if unnecessary
     */
    showFooter(): boolean {
      const hiddenFooterRouteNames = new Set(["Maintenance", "ImageGallery", "Coupons"]);
      const currentRouteName = this.$route.name;
      return !!currentRouteName && !hiddenFooterRouteNames.has(currentRouteName);
    },
    showFloatingFooter(): boolean {
      return (
        this.showFooter && this.layoutType === LayoutType.FloatingFooter && this.website.relatedItemList.length > 0
      );
    },
    isIndexOrDetail(): boolean {
      return this.$route.name === "Index" || this.$route.name === "Place";
    },
  },
  watch: {
    $route: function (newRoute) {
      if (this.theme === "black") {
        if (newRoute.name === "Place") {
          this.currentTheme = "place-black";
        } else {
          this.currentTheme = "dark-black";
        }
      } else {
        this.currentTheme = "white";
      }
      // check for specific place route
    },
    theme: {
      handler: function (theme) {
        if (theme === "black") {
          if (this.$route.name === "Place") {
            this.currentTheme = "place-black";
          } else {
            this.currentTheme = "dark-black";
          }
        } else {
          this.currentTheme = "white";
        }
      },
      deep: true,
    },
  },
  mounted() {
    const previousContrastSetting = storage.get("contrast");
    this.contrastSetting = previousContrastSetting || "normal";
    this.askInitialContrastSetting = !previousContrastSetting;

    /** start of codes for Reserved Maintenance **/

    // const startMaintenanceTime = 1621270800;
    // const endMaintenanceTime = 1621274400;
    // const currentUnixtime = new Date().getTime() / 1000;

    // // Check if maintenance time has passed
    // if (currentUnixtime <= endMaintenanceTime) {
    //   // Check if you visit the page between the time
    //   if (currentUnixtime >= startMaintenanceTime && this.$route.name !== "Maintenance") {
    //     this.$router.push("/maintenance");
    //   }
    //   // Set all the current loaded stuff to maintenance
    //   setTimeout(() => {
    //     this.$router.push("/maintenance").catch(() => {
    //       console.info("already navigated to maintenance");
    //     });
    //     // Return to the previous page after 1 hour
    //   }, (startMaintenanceTime - currentUnixtime) * 1000);
    //   setTimeout(() => {
    //     this.$router.go(-1);
    //   }, (endMaintenanceTime - currentUnixtime) * 1000);
    // }

    /** end of codes for Reserved Maintenance **/
  },
  methods: {
    toggleTheme() {
      // Do NOT change website theme setting from devtools. Use override methods.
      this.$store.commit("overrideTheme", this.currentTheme === "white" ? "black" : "white");
    },
    openContrastSettingModal() {
      this.showContrastSetting = true;
      this.hideContrastSettingToast();
    },
    closeContrastSettingModal() {
      this.showContrastSetting = false;
      this.contrastSetting = storage.get("contrast") || "normal";
    },
    hideContrastSettingToast() {
      this.askInitialContrastSetting = false;
    },
  },
});
