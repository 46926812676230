
import Vue, { PropType } from "vue";
import dayjs from "dayjs";

import { Coupon } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

export default Vue.extend({
  name: "place-coupon",
  props: {
    coupon: { type: Object as PropType<Coupon.Coupon>, required: true },
  },
  methods: {
    discountValue(coupon: Coupon.Coupon): string {
      switch (coupon.discountUnit) {
        case "yen":
          return `¥${coupon.discountAmount}`;
        case "percent":
          return `${coupon.discountAmount}%`;
        case "usd":
          return `$${coupon.discountAmount}`;
        case "twd":
        case "ntd":
          return `NT$${coupon.discountAmount}`;
        case "free":
          return this.$i18n.t("coupon.free") as string;
        default:
          return "";
      }
    },
    discountPostfix(coupon: Coupon.Coupon): string {
      switch (coupon.discountUnit) {
        case "yen":
        case "percent":
        case "usd":
        case "twd":
        case "ntd":
          return this.$i18n.t("coupon.discount") as string;
        case "free":
        default:
          return "";
      }
    },
    toDatetime(datetime: string): string {
      return dayjs(datetime).format("YYYY/MM/DD HH:mm");
    },
    toTime(time: string): string {
      const dummyDate = "2000/01/01";
      return dayjs(`${dummyDate} ${time}`).format("HH:mm");
    },
  },
});
