import { extend } from "vee-validate";
import { required, email, numeric, max } from "vee-validate/dist/rules";
import { i18n } from "./i18n";

extend("required", {
    ...required,
    message: (field) => i18n.t("feature.qticket.validation.messages.required", i18n.locale, [field]).toString(),
});

extend("email", {
    ...email,
    message: () => i18n.t("feature.qticket.validation.messages.invalidEmail").toString(),
});

extend("number", {
    ...numeric,
    message: () => i18n.t("feature.qticket.validation.messages.invalidNumber").toString(),
});

extend("max", {
    ...max,
    message: () => i18n.t("feature.qticket.validation.messages.invalidLength").toString(),
});

extend("mobile_phone", {
    message: () => i18n.t("feature.qticket.validation.messages.sms").toString(),
    validate: (value: string) => /^0[7-9]0-?[0-9]{4}-?[0-9]{4}$/.test(value),
});
