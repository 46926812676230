
import Vue from "vue";

export default Vue.extend({
  name: "closed-media-view",
  props: {
    mediaName: { type: String, default: "" },
  },
  beforeMount() {
    // Fix white theme.
    this.$store.commit("setPageTheme", "white");
  },
  methods: {
    onClickButton: function () {
      window.location.href = "https://corp.vacan.com/";
    },
  },
});
