
import MixinPlace from "@/components/place/mixin.vue";

import { Place as BackendPlace, Http, Ticket } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import ImageWithFallback from "@/components/image-with-fallback.vue";
import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VIcon from "@/components/v/icon.vue";
import VButton from "@/components/v/button.vue";
import CountText from "@/components/place/count-text.vue";
import NotificationModal from "@/components/notification-modal.vue";
import CongestionRateText from "@/components/place/congestion-rate-text.vue";

import * as storage from "@/api/local-storage";

type Equipment = BackendPlace.Equipment;

interface Profile {
  togo: boolean;
}

export default MixinPlace.extend({
  name: "place-card-white-3-images",
  components: {
    ImageWithFallback,
    LabelSet,
    VacancyText,
    VIcon,
    VButton,
    CountText,
    NotificationModal,
    CongestionRateText,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
      //default: (): undefined => undefined,
    },
    qticketConfig: {
      type: Object as () => (Ticket.TicketConfig & { placeIdHash: string }) | undefined,
      default: undefined,
    },
    profile: { type: Object as () => Profile | undefined, default: undefined },
    customizeForOmachi: { type: Boolean, default: false },
  },
  data() {
    return {
      isShowNotificationModal: false,
    };
  },
  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.currentGender;
    },
    showQtDisabledText(): boolean {
      return this.isQtNotAvailable && !["closed", "lo"].includes(this.vacancy.status.state);
    },
    isQtOnlineDisabled(): boolean {
      // undefined -> true
      return this.qticketConfig?.useOnlineReception === false;
    },
    isQtNotAvailable(): boolean {
      return (
        !this.vacancy.qticketStatus.isAcceptable ||
        (this.vacancy.qticketStatus.useSizeLimit && this.vacancy.qticketStatus.availableSize === 0)
      );
    },
    isQtFirstGroupDisabled(): boolean {
      // undefined -> true
      return (
        this.vacancy.qticketStatus.isAcceptable &&
        this.vacancy.status.state === "vacancy" &&
        this.qticketConfig?.canStartOnline !== true
      );
    },
    isQtDisabled(): boolean {
      return (
        this.isQtOnlineDisabled ||
        this.isQtNotAvailable ||
        this.isQtFirstGroupDisabled ||
        this.vacancy.status.state === "closed" ||
        this.vacancy.status.state === "lo"
      );
    },
    isQtActivated(): boolean {
      return (
        this.place.featureList.includes("qticket") &&
        !!this.qticketConfig &&
        this.qticketConfig?.disableAllNotification !== true
      );
    },
    shopClosing(): boolean {
      return (
        this.vacancy.status.state === "closed" ||
        this.vacancy.status.state === "lo" ||
        this.vacancy.status.state === "maintenance"
      );
    },
    locale(): string {
      return this.$i18n.locale;
    },
    modalCondition(): boolean {
      return this.isShowNotificationModal;
    },
    useLineNotification(): boolean {
      return (
        this.qticketConfig?.notificationTarget === "email" && this.qticketConfig?.isEnabledLineNotification === true
      );
    },
  },
  methods: {
    showNotificationModal(): void {
      if (!this.useLineNotification) {
        storage.remove("USE_LINE_NOTIFICATION");
        return;
      }
      this.isShowNotificationModal = true;
    },
    closeNotificationModal(modalCondition: boolean): void {
      this.isShowNotificationModal = modalCondition;
    },
  },
});
