
import Vue, { PropType } from "vue";
import dayjs from "dayjs";

type Weekday = {
  date: string;
  unixtime: number;
  isWorkday: boolean;
};

export default Vue.extend({
  name: "v-date-selector-button",
  props: {
    weekday: { type: Object as PropType<Weekday>, default: undefined },
    selected: Boolean,
  },
  computed: {
    isWorkday(): boolean {
      if (this.weekday === undefined) return true;
      return this.weekday.isWorkday;
    },
    day(): string {
      if (this.weekday === undefined) return "";
      return dayjs.unix(this.weekday.unixtime).format("ddd");
    },
    date(): string {
      if (this.weekday === undefined) return "";
      return dayjs.unix(this.weekday.unixtime).format(this.selected ? "M/D" : "D");
    },
  },
  methods: {
    onSelect() {
      this.$emit("select", this.weekday.date);
    },
  },
});
