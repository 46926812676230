
import Vue from "vue";
import { mapGetters } from "vuex";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
  Ticket,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VButton from "@/components/v/button.vue";
import Icon from "@/components/v/icon.vue";
import CountText from "../count-text.vue";
import NotificationModal from "@/components/notification-modal.vue";
import VDateSelector from "@/components/place/forecast/date-selector.vue";
import LinkCopyButton from "@/components/place/link-copy-button.vue";
import NaviButton from "@/components/place/navi-button.vue";
import * as storage from "@/api/local-storage";
import { ForecastState, Prediction } from "@/store";
import ForecastChart from "../forecast/forecast-chart.vue";
import PredictionChart from "../prediction/prediction-chart.vue";
import PlacePanelPromo from "@/components/place/panel/promo.vue";
import dayjs from "dayjs";
import CongestionRateText from "@/components/place/congestion-rate-text.vue";

type Equipment = BackendPlace.Equipment;
type QticketStatus = BackendVacancy.QticketStatus;
type HotSpringSettingValue = Http.HotSpringSettingValue;

interface GeoLocation {
  latitude: number;
  longitude: number;
}

export default Vue.extend({
  name: "panel-summary",
  components: {
    LabelSet,
    VacancyText,
    VButton,
    Icon,
    CountText,
    NotificationModal,
    VDateSelector,
    ForecastChart,
    PredictionChart,
    PlacePanelPromo,
    LinkCopyButton,
    NaviButton,
    CongestionRateText,
  },
  props: {
    floor: { type: String, required: false, default: "" },
    area: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "-" },
    categoryList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    hasCoupon: { type: Boolean, default: false },
    // vacancy-text props
    vacancyStatus: { type: String, required: false, default: "closed" },
    vacancyText: { type: String, required: false, default: "-" },
    countText: { type: String, required: false, default: "-" },
    vacancyStyle: { type: String, required: false, default: "level" },
    // label-set props
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    // feature props
    featureList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // qticket props
    qticketStatus: {
      type: Object as () => QticketStatus,
      required: false,
      default: (): QticketStatus => ({ isAcceptable: false, useSizeLimit: false }),
    },
    qticketConfig: {
      type: Object as () => Ticket.TicketConfig | undefined,
      required: false,
      default: (): Ticket.TicketConfig | undefined => undefined,
    },
    // onsen props
    hotSpringOpeningHoursList: {
      type: Array as () => HotSpringSettingValue[],
      required: false,
      default: (): HotSpringSettingValue[] => [],
    },
    currentGender: { type: String, required: false, default: undefined },
    // display settings
    isDisplayBusinessHour: { type: Boolean, default: true },
    placeIdHash: { type: String, required: true, default: undefined },
    // forecast props
    showForecast: { type: Boolean, default: false },
    forecastState: {
      type: Object as () => ForecastState,
      default: () => ({
        enabledPlaceList: [],
        forecastDictionary: {} as { [date: string]: { [placeIdHash: string]: BackendVacancy.VacancyOfHour[] } },
        selectedDate: "",
        vacancyHistory: {} as { [placeIdHash: string]: BackendVacancy.VacancyOfHour[] },
        vacancyStateThresholdDictionary: {} as Record<string, BackendVacancy.VacancyStateThreshold>,
      }),
    },
    profile: { type: Object, required: false, default: undefined },
    omachishiGeoLocation: {
      type: Object as () => GeoLocation | undefined,
      default: undefined,
    },
  },
  data() {
    return {
      isShowNotificationModal: false,
      intersectionObserver: new window.IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.$gtag.event("Intersect Forecast Graph");
        }
      }),
      predictionPlaceInfo: {
        //dev
        //pnyZWDlX：サイネージ検証place39
        //jX51QPQX：サイネージ検証place40
        //prod stg
        //5r0aGDnO：キッチン&マーケット内 イートインスペース
        //4r6Py1XJ：スープストック
        pnyZWDlX: -1,
        jX51QPQX: 2,
        "5r0aGDnO": -1,
        "4r6Py1XJ": 2,
      } as Record<string, number>,
    };
  },
  computed: {
    ...(mapGetters({
      predictionState: "getJrWestPrediction",
    }) as {
      predictionState: () => Prediction[];
    }),
    showPromo(): boolean {
      if (this.profile === undefined) {
        return false;
      }
      return this.profile.title || this.profile.body || (this.profile.togo && this.profile.togoUrl);
    },
    showFloor(): boolean {
      return this.floor.length > 0;
    },
    showCategoryList(): boolean {
      return this.categoryList.length > 0;
    },
    showQtDisabledText(): boolean {
      return this.isQtNotAvailable && !["closed", "lo"].includes(this.vacancyStatus);
    },
    isQtOnlineDisabled(): boolean {
      // undefined -> true
      return this.qticketConfig?.useOnlineReception === false;
    },
    isQtNotAvailable(): boolean {
      return (
        !this.qticketStatus.isAcceptable || (this.qticketStatus.useSizeLimit && this.qticketStatus.availableSize === 0)
      );
    },
    isQtFirstGroupDisabled(): boolean {
      // undefined -> true
      return (
        this.qticketStatus.isAcceptable &&
        this.vacancyStatus === "vacancy" &&
        this.qticketConfig?.canStartOnline !== true
      );
    },
    isQtDisabled(): boolean {
      return (
        this.isQtOnlineDisabled ||
        this.isQtNotAvailable ||
        this.isQtFirstGroupDisabled ||
        this.vacancyStatus === "closed" ||
        this.vacancyStatus === "lo"
      );
    },
    isQtActivated(): boolean {
      return (
        this.featureList.includes("qticket") &&
        !!this.qticketConfig &&
        this.qticketConfig?.disableAllNotification !== true
      );
    },
    isShopClosed(): boolean {
      return this.vacancyStatus === "closed" || this.vacancyStatus === "lo" || this.vacancyStatus === "maintenance";
    },
    locale(): string {
      return this.$i18n.locale;
    },
    modalCondition(): boolean {
      return this.isShowNotificationModal;
    },
    useLineNotification(): boolean {
      return (
        this.qticketConfig?.notificationTarget === "email" && this.qticketConfig?.isEnabledLineNotification === true
      );
    },
    forecastDataList(): number[] {
      if (!this.forecastState.enabledPlaceList.find((enabled) => enabled === this.placeIdHash)) {
        return [];
      }
      const forecast = this.forecastState.forecastDictionary[this.forecastState.selectedDate][this.placeIdHash];
      return forecast
        ? forecast.map((forecastData: BackendVacancy.VacancyOfHour) => forecastData.congestionPercent ?? -1)
        : [];
    },
    historyDataList(): (number | undefined)[] {
      const now = dayjs().format("YYYYMMDD");
      return this.forecastState.selectedDate === now
        ? this.forecastState.vacancyHistory[this.placeIdHash].map((data) => data.congestionPercent)
        : [];
    },
    vacancyStateThreshold(): BackendVacancy.VacancyStateThreshold | undefined {
      return this.forecastState.vacancyStateThresholdDictionary[this.placeIdHash];
    },
    forecastNotFound(): boolean {
      return this.forecastDataList.length <= 0;
    },
    forecastIsEmpty(): boolean {
      return this.forecastDataList.every((value) => value <= 0);
    },
    isPredictionShown(): boolean {
      return Object.keys(this.predictionPlaceInfo).includes(this.placeIdHash);
    },
  },
  mounted() {
    setTimeout(() => {
      const element = this.$refs.forecast;
      if (!(element instanceof Element)) {
        return;
      }
      this.intersectionObserver.observe(element);
    }, 1000);
    if (this.isPredictionShown) {
      this.$store.dispatch("fetchJrWestVacancyPrediction", {
        point: this.predictionPlaceInfo[this.placeIdHash],
      });
    }
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      const element = this.$refs.forecast;
      if (!(element instanceof Element)) {
        return;
      }
      this.intersectionObserver.unobserve(element);
    }
  },
  methods: {
    showNotificationModal(): void {
      if (!this.useLineNotification) {
        this.$emit("open-qticket");
        storage.remove("USE_LINE_NOTIFICATION");
        return;
      }
      this.isShowNotificationModal = true;
    },
    closeNotificationModal(modalCondition: boolean): void {
      this.isShowNotificationModal = modalCondition;
    },
    onDateSelected(date: string) {
      this.$store.commit("setSelectedDate", date);
    },
  },
});
