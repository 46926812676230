var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ hover_screen: _vm.modalCondition },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeNotificationModal.apply(null, arguments)}}},[(_vm.modalCondition)?_c('div',{staticClass:"notification-modal"},[_c('span',{staticClass:"material-icons close",on:{"click":_vm.closeNotificationModal}},[_vm._v("close")]),_c('h2',{staticClass:"name"},[_vm._v(_vm._s(_vm.placeName))]),_c('p',{staticClass:"notification-methods"},[_vm._v(_vm._s(_vm.$t("notificationModal.methods")))]),_c('router-link',{attrs:{"to":{
        name: 'QT/Entry',
        params: { ..._vm.params, placeIdHash: _vm.params.placeIdHash },
        query: {
          'skip-tutorial': _vm.qticketConfig !== undefined && _vm.qticketConfig.disableNotification ? true : undefined,
        },
      },"tag":"span"}},[_c('v-button',{staticClass:"set-up line",on:{"click":_vm.useLine}},[_vm._v(" "+_vm._s(_vm.$t("notificationModal.line"))+" ")])],1),_c('router-link',{attrs:{"to":{
        name: 'QT/Entry',
        params: { ..._vm.params, placeIdHash: _vm.params.placeIdHash },
        query: {
          'skip-tutorial': _vm.qticketConfig !== undefined && _vm.qticketConfig.disableNotification ? true : undefined,
        },
      },"tag":"span"}},[_c('v-button',{staticClass:"set-up email"},[_vm._v(" "+_vm._s(_vm.$t("notificationModal.mail"))+" ")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }