
import Vue from "vue";
import VButton from "@/components/v/button.vue";
import * as storage from "@/api/local-storage";

export default Vue.extend({
  name: "color-contrast-setting-modal",
  components: {
    VButton,
  },
  data() {
    return {
      selectedContrast: "normal",
    };
  },
  mounted() {
    const previousSetting = storage.get("contrast");
    this.selectedContrast = previousSetting || "normal";
    storage.set("contrast", this.selectedContrast);
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    changeContrast() {
      storage.set("contrast", this.selectedContrast);
      this.closeModal();
    },
  },
});
