
import Vue from "vue";

export default Vue.extend({
  name: "v-questionnaire",
  props: {
    url: { type: String, default: "" },
    label: { type: String, default: "" },
  },
  methods: {
    trackClickEvent() {
      this.$gtag.event("click", {
        event_category: "アンケートクリック: トップページ",
        event_label: this.label,
        value: 1,
      });
    },
  },
});
